import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { FaRegMap } from "react-icons/fa";

const Footer = (props) => {
    return (
        <div className={`footer ${props.className}`}>
                    <div className="col-left col">
                        <Link className="link" to={'/'}>
                            <GoHome className="icon icon-footer" />
                            
                        </Link>
                        <Link className="link" to={'/map'}>
                            <FaRegMap className="icon icon-footer" />
                            
                        </Link>
{props.children}


                    </div>
                    
                </div>
    )
}

export default Footer;
import { ActionTypes } from "../constants/actionTypes";



export const loadTours = (tours) =>{
    return {
        type: ActionTypes.LOAD_TOURS,
        payload: tours
    }
}

export const selectedSpace = (space) => {
    return {
        type: ActionTypes.SELECTED_SPACE,
        payload: space
    }
}
import "./../styles/Header.scss";

import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";

const Header = (props) => {

const closeWindow = (e) => {
    e.preventDefault();
    window.close();
}

    return (
        <div className={`header ${props.className}`}>

            <IoMdHelpCircleOutline className="icon icon-header" />
            <div className="header-title">
                {props.title}
            </div>
            <IoCloseSharp className="icon icon-header" onClick={closeWindow}/>

        </div>
    )
}

export default Header;

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { BarcodeDetector } from "barcode-detector";
import { selectedSpace } from "../redux-store/actions/tourActions";


import "./../styles/ScanPage.scss";


const ScanPage = () => {

  const [code, setCode] = useState();
  const [cwidth, setCWidth] = useState(600);
  const [cheight, setCHeight] = useState(400);
  const [pageName, setPageName] = useState("");
  let intervalId = null;

  const spaces = useSelector((state) => state.tours.tours.spaces);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // Create new barcode detector
  const barcodeDetector = new BarcodeDetector({ formats: ['qr_code'] });
  let video = null;
  let exploreBtn = null;

  let codes = [];
  const seen = new Set();

  let barcodeVal = null;


  // On component mount
  useEffect(() => {
    console.log('Scanner DidMount ', intervalId);
    console.log(document.body.clientWidth)
    console.log(document.body.clientHeight)
    
    setCWidth(document.body.clientWidth);
    setCHeight(document.body.clientHeight);

    if (!intervalId) 
       getVideoElement();

    exploreBtn = document.getElementById("btn-explore");

    return () => {
      // Anything in here is fired on component unmount.
      clearInterval(intervalId);
      console.log('Scanner will Unmount');
      
    }

  }, []);


  function stopStreamedVideo() {
    const videoElem = document.getElementById('video');
    const stream = videoElem.srcObject;
  const tracks = stream.getTracks();

  tracks.forEach((track) => {
    track.stop();
  });

  //videoElem.pause();

  videoElem.srcObject = null;

  }


  function getVideoElement() {
    console.log("getVideoElement");
    // Get video element 
    video = document.getElementById('video');

    // Check for a camera
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          facingMode: 'environment',
          width: 1920,
        },
        audio: false
      };

      // Start video stream
      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          video.srcObject = stream;
        });

      video.addEventListener("loadedmetadata", () => {
        video.play();
        if(!intervalId){
          console.log("Interval Not created")
          intervalId = setInterval(detectCode, 100);
        }
        
      });
    }

  }




  // Codes proxy/state
  const codesProxy = new Proxy(codes, {
    set(target, prop, value, receiver) {
      // Throw err if value is a number
      // Stops from saving undefined codes
      if (typeof value === 'number') throw value;

      target.push(value);

      // Check if code has already been scanned
      target = target.filter((c) => {
        if (c.rawValue !== barcodeVal) return c;
        const d = seen.has(c.rawValue);
        seen.add(c.rawValue);
        
        return !d;
      })

      setCode(value.rawValue);
      
      
      
      // Select the container scanned
      //const scanned = document.querySelector('#scanned')
      //const temp = document.createElement('scaned-item')
      // const format = document.createElement('span')
      // const rawValue = document.createElement('span')

      // Goes into the custom elements formate slot
      //format.setAttribute('slot', 'format');
      //format.innerHTML = value.format;

      // Goes into the custom elements raw slot 
      //rawValue.setAttribute('slot', 'raw');
      // rawValue.innerHTML = value.rawValue;

      // Append elements to custom element
      //temp.appendChild(rawValue);
      //temp.appendChild(format);

      // Append Custom element to scanned container
      //scanned.appendChild(temp);
      exploreBtn.style.display = "block";
      return true;
    }
  });


  const drawCodePath = ({ cornerPoints }) => {
   
    const canvas = document.querySelector('#canvas');
    const ctx = canvas.getContext('2d');
    ctx.scale(1, 1);
    const strokeGradient = ctx.createLinearGradient(0, 0, canvas.scrollWidth, canvas.scrollHeight);

    // Clear canvas for new redraw
    
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Exit function and clear canvas if no corner points
    if (!cornerPoints) {
      exploreBtn.style.display = "none";
      return;
    }


    exploreBtn.style.display = "block";
    // Create new gradient
    strokeGradient.addColorStop('0', '#c471ed');
    strokeGradient.addColorStop('1', '#f7797d');

    // Define stoke styles
    ctx.strokeStyle = strokeGradient;
    ctx.lineWidth = 4;

    // Begin draw
    ctx.beginPath();

    // Draw code outline path
    for (const [i, { x, y }] of cornerPoints.entries()) {
      if (i === 0) {
        // Move x half of the stroke width back 
        // makes the start and end corner line up
        ctx.moveTo(x - ctx.lineWidth / 2, y);
        continue;
      }

      // Draw line from current pos to x, y
      ctx.lineTo(x, y);

      // Complete square draw to starting position
      if (i === cornerPoints.length - 1) ctx.lineTo(cornerPoints[0].x, cornerPoints[0].y);
    }

    // Make path to stroke
    ctx.stroke();
  }

  const detectCode = () => {
   //console.log("detect");
    barcodeDetector.detect(video).then(codes => {
      // If no codes exit function and clear canvas
      //if (codes.length === 0) return drawCodePath({});
      /* if (codes.length === 0){
        exploreBtn.style.display = "none";
        }else{
          exploreBtn.style.display = "block";
        } */

      for (const barcode of codes) {
        
        // Draw outline
        //drawCodePath(barcode);

        // Code in seen set then exit loop 
      setPageName(JSON.parse(barcode.rawValue).space);
        if (seen.has(barcode.rawValue)) return;

        // Save barcode to window to use later on
        // then push to the codes proxy
        barcodeVal = barcode.rawValue;
        codesProxy.push(barcode);
        

      }
    }).catch(err => {
      console.error(err);
    })
  }

  const onGoToTour = () => {
    const scanned_space = JSON.parse(code).space;
    const scanned_page = JSON.parse(code).page;

    const space_object = spaces.find((space) => space.space_name === scanned_space);
    dispatch(selectedSpace(space_object));

    navigate(`/tour/${space_object.space_id}`);

  }

  return (
    <div className="app-container">

      <video id="video" width={cwidth} height={cheight}>

      </video>
      <canvas id="canvas" width={cwidth} height={cheight}></canvas>
      <Header className="header-scanner" title={"Scan the QR code"} />

      <button id="btn-explore" className="btn" onClick={onGoToTour}>Explore the {pageName}</button>
      {/* <button id="btn-stop" style={{ "display": "block" }} className="btn" onClick={stopStreamedVideo}>Stop Video</button> */}
      <Footer className="footer-scanner" />
    </div>
  );
}

export default ScanPage;
import { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

// External Libraries
import * as THREE from 'three';
import { isMobile } from 'react-device-detect';
import gsap from 'gsap';

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';
import Artefact from '../components/Artefact';

// Assets
import poiSvg from './../assets/poi.svg';

// Icons
import { BiMobileVibration } from "react-icons/bi";
import { TbDeviceMobileOff } from "react-icons/tb";
import { IoCloseSharp } from "react-icons/io5";
import { LuPlay } from "react-icons/lu";
import { GrPause } from "react-icons/gr";
import { GrStop } from "react-icons/gr";

import "./../styles/TourPage.scss";

let container;

// Image Sphere
let sphereGeometry;
let sphereMesh;

// Transition Sphere
let tSphereGeometry
let tSphereMesh;

// Video Sphere
let vSphereGeometry;
let vSphereMesh;

let poiGroup;
let camera, scene, renderer;
let frustum = new THREE.Frustum();
let cameraViewProjectionMatrix = new THREE.Matrix4();
let cWidth, cHeight;
let video;
let autoRotate = false;

const TourPage = () => {

    const spaces = useSelector((state) => state.tours.tours.spaces);
    const dispatch = useDispatch();
    const { space_id } = useParams();
    const navigate = useNavigate();

    const [space, setSpace] = useState({ space_hotspots: [], history:[] });
    const [yearMarks, setYearMarks] = useState([]);
    const [auto, setAuto] = useState(false);
    const [isSetupReady, setIsSetupReady] = useState(false);
    const [selectedHotspot, setSelectedHotspot] = useState(null);
    const [closePop, setClosePop] = useState(false);
    const [videoState, setVideoState] = useState('stop');

    let isCanvas = false;

    let heading = 0;

    let isUserInteracting = false,
        onPointerDownMouseX = 0, onPointerDownMouseY = 0,
        lon = 0, onPointerDownLon = 0,
        lat = 0, onPointerDownLat = 0,
        phi = 0, theta = 0;



    useEffect(() => {
        console.log("space_id", space_id);
        console.log("isSetupReady", isSetupReady);
        let s = spaces.find((s) => parseInt(s.space_id) === parseInt(space_id));
        setSpace(s);
    }, [space_id])


    useEffect(() => {
        let s = spaces.find((s) => parseInt(s.space_id) === parseInt(space_id));
        setSpace(s);
        setAuto(isMobile);
    }, []);

    useEffect(() => {
        autoRotate = auto;
        console.log("aa", auto);
    }, [auto])

    useEffect(() => {
        //console.log("space", space);

        if(space.history){
            let m = buildYearMarkers(space.history);
            setYearMarks(m);
        } 

        if (!isCanvas && space) {
            if (isSetupReady) {
                loadScene();
            } else {
                init();
                setIsSetupReady(true);
                isCanvas = !isCanvas;
            }
        }

    }, [space]);

    const buildYearMarkers = (years)=> {

        let markers = [];
        let step = 100/years.length;
        markers.push({value:0, label:"Now"});
        years.forEach((year, index) => {
            markers.push({value: step * (index+1), label:`${year.year}`})
        });

        console.log("Markers", markers);
        return markers;
    }

    function init() {

        video = document.getElementById('video');
        setTimeout(() => video.load(), 1000);

        frustum = new THREE.Frustum();
        cameraViewProjectionMatrix = new THREE.Matrix4();

        container = document.getElementById('container');

        cWidth = window.innerWidth;
        cHeight = window.innerHeight;

        camera = new THREE.PerspectiveCamera(75, cWidth / cHeight, 1, 1100);

        scene = new THREE.Scene();


        // Image Sphere
        sphereGeometry = new THREE.SphereGeometry(500, 60, 40);
        // invert the geometry on the x-axis so that all of the faces point inward
        sphereGeometry.scale(- 1, 1, 1);
        const texture = new THREE.TextureLoader().load(`${window.location.origin}/assets/tour-images/${space.space_image}`);
        texture.colorSpace = THREE.SRGBColorSpace;
        const material = new THREE.MeshBasicMaterial({ map: texture, transparent:true });
        sphereMesh = new THREE.Mesh(sphereGeometry, material);
        scene.add(sphereMesh);



        // Video Sphere
      
        vSphereGeometry = new THREE.SphereGeometry(490, 60, 40);
        // invert the geometry on the x-axis so that all of the faces point inward
        vSphereGeometry.scale(- 1, 1, 1);
        const vMaterial = new THREE.MeshBasicMaterial({ color:"#ff00ff", transparent:true });
        vSphereMesh = new THREE.Mesh(vSphereGeometry, vMaterial);
        vSphereMesh.visible = false;
        scene.add(vSphereMesh);

        // Video Sphere
      
        tSphereGeometry = new THREE.SphereGeometry(495, 60, 40);
        // invert the geometry on the x-axis so that all of the faces point inward
        tSphereGeometry.scale(- 1, 1, 1);
        const tMaterial = new THREE.MeshBasicMaterial({ color:"#ff00ff", transparent:true });
        tSphereMesh = new THREE.Mesh(tSphereGeometry, tMaterial);
        tSphereMesh.visible = false;
        scene.add(tSphereMesh);

        poiGroup = new THREE.Group();

        scene.add(poiGroup);

        reloadPois();

        renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(cWidth, cHeight);
        renderer.setAnimationLoop(animate);
        container.appendChild(renderer.domElement);

        container.style.touchAction = 'none';

        container.addEventListener('pointerdown', onPointerDown);

        document.addEventListener('wheel', onDocumentMouseWheel);

        //

        document.addEventListener('dragover', function (event) {

            event.preventDefault();
            event.dataTransfer.dropEffect = 'copy';

        });

        document.addEventListener('dragenter', function () {

            document.body.style.opacity = 0.5;

        });

        document.addEventListener('dragleave', function () {

            document.body.style.opacity = 1;

        });

        document.addEventListener('drop', function (event) {

            event.preventDefault();

            const reader = new FileReader();
            reader.addEventListener('load', function (event) {

                material.map.image.src = event.target.result;
                material.map.needsUpdate = true;

            });
            reader.readAsDataURL(event.dataTransfer.files[0]);

            document.body.style.opacity = 1;

        });

        //

        window.addEventListener('resize', onWindowResize);

    }

    function loadScene(image=null) {
        if(image==null){
            image = `${window.location.origin}/assets/tour-images/${space.space_image}`;
        }else{
            image = `${window.location.origin}/assets/tour-images/${image}`;
        }
        tSphereMesh.visible = true;
        tSphereMesh.material = sphereMesh.material;
   
        gsap.fromTo(tSphereMesh.material, {opacity: 1}, {opacity: 0, duration:0.5})

        const texture = new THREE.TextureLoader().load(image);
        texture.colorSpace = THREE.SRGBColorSpace;
        const material = new THREE.MeshBasicMaterial({ map: texture, transparent:true });
        sphereMesh.material = material;
        reloadPois();
        setTimeout(() => video.load(), 1000);
    }

    function onPlayVideo() {
        const texture = new THREE.VideoTexture( video );
        texture.colorSpace = THREE.SRGBColorSpace;
        const material = new THREE.MeshBasicMaterial( { map: texture } );
        vSphereMesh.material = material;
    }


    function reloadPois() {
        let obj, i;

        //              
        for (i = poiGroup.children.length - 1; i >= 0; i--) {
            obj = poiGroup.children[i];
            poiGroup.remove(obj);
        }

        let sp = new THREE.SphereGeometry(15, 32, 16);
        let planematerial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

        for (i = 0; i < space.space_hotspots.length; i++) {
            console.log(space.space_hotspots[i].hotspot_coords.x);
            let object = new THREE.Mesh(sp, planematerial);
            object.position.x = parseInt(space.space_hotspots[i].hotspot_coords.x);
            object.position.y = parseInt(space.space_hotspots[i].hotspot_coords.y);
            object.position.z = parseInt(space.space_hotspots[i].hotspot_coords.z);
            object.hotspot_id = space.space_hotspots[i].hotspot_id;
            object.visible = false;
            poiGroup.add(object);
        }
    }



    function showHotspotOnScreen() {
        let vector;
        let visible;

        for (let i = 0; i < poiGroup.children.length; i++) {
            let obj = poiGroup.children[i];

            vector = toScreenPosition(obj);
            visible = toScreenVisible(obj);

            //console.log(vector, visible);
            //console.log("poi-" + obj.hotspot_id);



            if (document.getElementById("poi-" + obj.hotspot_id)) {

                if (visible && vector.x > 0 && vector.x < cWidth && vector.y > 0 && vector.y < cHeight) {
                    document.getElementById("poi-" + obj.hotspot_id).style.display = "flex";
                    document.getElementById("poi-" + obj.hotspot_id).style.left = vector.x + "px";
                    document.getElementById("poi-" + obj.hotspot_id).style.top = vector.y + "px";
                } else {
                    document.getElementById("poi-" + obj.hotspot_id).style.left = 0 + "px";
                    document.getElementById("poi-" + obj.hotspot_id).style.top = 0 + "px";

                    document.getElementById("poi-" + obj.hotspot_id).style.display = "none";
                }

            }

        }
    }



    function toScreenPosition(obj) {


        var vector = new THREE.Vector3();
        obj.updateMatrixWorld();

        vector.setFromMatrixPosition(obj.matrixWorld);
        vector.project(camera);

        let width = cWidth;//renderer.getContext().canvas.width;
        let height = cHeight; //renderer.getContext().canvas.height;



        vector.x = Math.round((vector.x + 1) * width / 2);
        vector.y = Math.round(-(vector.y - 1) * height / 2);
        return {
            x: vector.x,
            y: vector.y
        };

    }

    function toScreenVisible(obj) {

        camera.updateMatrixWorld();

        cameraViewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
        frustum.setFromProjectionMatrix(cameraViewProjectionMatrix);

        var visible = frustum.intersectsObject(obj);

        return visible;
    }

    function onWindowResize() {
        cWidth = window.innerWidth;
        cHeight = window.innerHeight;

        camera.aspect = cWidth / cHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(cWidth, cHeight);

    }

    function onPointerDown(event) {

        if (event.isPrimary === false) return;

        isUserInteracting = true;

        onPointerDownMouseX = event.clientX;
        onPointerDownMouseY = event.clientY;

        onPointerDownLon = lon;
        onPointerDownLat = lat;

        document.addEventListener('pointermove', onPointerMove);
        document.addEventListener('pointerup', onPointerUp);

    }

    function onPointerMove(event) {

        if (event.isPrimary === false) return;

        lon = (onPointerDownMouseX - event.clientX) * 0.1 + onPointerDownLon;
        lat = (event.clientY - onPointerDownMouseY) * 0.1 + onPointerDownLat;

    }

    function onPointerUp(event) {

        if (event.isPrimary === false) return;

        isUserInteracting = false;

        document.removeEventListener('pointermove', onPointerMove);
        document.removeEventListener('pointerup', onPointerUp);

    }

    function onDocumentMouseWheel(event) {

        const fov = camera.fov + event.deltaY * 0.05;

        camera.fov = THREE.MathUtils.clamp(fov, 10, 75);

        camera.updateProjectionMatrix();

    }

    const animate = () => {

        // Autoplay
        if (isUserInteracting === false) {
            //lon += 0.1;
        }

        //  console.log("autoRotate", autoRotate);

        lat = Math.max(- 85, Math.min(85, lat));
        //console.log("autoRotate", autoRotate);
        if (autoRotate) {
            phi = THREE.MathUtils.degToRad(90 - verticalHeading);
            theta = heading;// THREE.MathUtils.degToRad(lon);

        } else {
            phi = THREE.MathUtils.degToRad(90 - lat);
            theta = THREE.MathUtils.degToRad(lon);
        }


        const x = 500 * Math.sin(phi) * Math.cos(theta);
        const y = 500 * Math.cos(phi);
        const z = 500 * Math.sin(phi) * Math.sin(theta);

        camera.lookAt(x, y, z);
        //console.log(parseInt(verticalHeading.toFixed(2)));

        showHotspotOnScreen();

        renderer.render(scene, camera);

    }




    let verticalHeading = 0;

    window.addEventListener("deviceorientation", onDeviceOrientation, true);

    function onDeviceOrientation(evt) {

        if (evt.alpha !== null) {
            heading = compassHeading(evt.alpha, evt.beta, evt.gamma);

            verticalHeading = evt.gamma < 0 ? evt.gamma - 90 : evt.gamma + 90;
        }

    }



    function compassHeading(alpha, beta, gamma) {

        // Convert degrees to radians
        var alphaRad = alpha * (Math.PI / 180);
        var betaRad = beta * (Math.PI / 180);
        var gammaRad = gamma * (Math.PI / 180);
        // Calculate equation components
        var cA = Math.cos(alphaRad);
        var sA = Math.sin(alphaRad);
        var cB = Math.cos(betaRad);
        var sB = Math.sin(betaRad);
        var cG = Math.cos(gammaRad);
        var sG = Math.sin(gammaRad);
        // Calculate A, B, C rotation components
        var rA = -cA * sG - sA * sB * cG;
        var rB = -sA * sG + cA * sB * cG;
        var rC = -cB * cG;
        // Calculate compass heading
        var compassHeading = Math.atan(rA / rB);
        // Convert from half unit circle to whole unit circle
        if (rB < 0) {
            compassHeading += Math.PI;
        } else if (rA < 0) {
            compassHeading += 2 * Math.PI;
        }

        // Convert radians to degrees
        //compassHeading *= 180 / Math.PI;

        return compassHeading;
    }

    const onToggleAutoRotate = (e) => {
        e.preventDefault();
        setAuto(!auto);


    }

    const gotoScene = (e) => {
        console.log("clicking");
        navigate("/tour/2");
    }

    const onHotspotCLicked = (e, hotspot) => {
        if (hotspot.hotspot_type === "info" || hotspot.hotspot_type === "artefact") {
            setSelectedHotspot(hotspot);
        } else {
            onStopVideo(e);
            navigate(`/tour/${hotspot.hotspot_data.hotspot_linked_to}`);
        }
    }

    const onClosePopup = (e) => {
        setClosePop(true);
        setTimeout(closePopup, 200)
    }

    const closePopup = () => {
        setSelectedHotspot(null)
        setClosePop(false);
    }


    const onTogglePlayPauseVideo = (e) => {
        e.preventDefault();
        vSphereMesh.visible = true;
        if(videoState === 'stop' || videoState === 'pause'){
            setVideoState('play')
            video.play();
            onPlayVideo();
        }else {
            setVideoState('pause');
            video.pause()
        }
    }

    const onStopVideo = (e) => {
        e.preventDefault();
        setVideoState('stop');
        vSphereMesh.visible = false;
        video.currentTime = 0;
        video.load();
    }


    const onYearMarkerChange = (e) => {
        let index = (e.target.value * (space.history.length))/100;

        if (index === 0){
            loadScene();
        }else{
            loadScene(space.history[index-1].asset_url);
        }
       
      }

    return (
        <div className="app-container">
            <Header className="header-tour" />
            <div id="container"></div>
            <div id="poi-container">
                <div className='pois'>
                    {
                        space.space_hotspots.map((hotspot) => {
                            return (
                                <div className='poi' key={`poi-${hotspot.hotspot_id}`} id={`poi-${hotspot.hotspot_id}`} onClick={(e) => onHotspotCLicked(e, hotspot)}>
                                    <img className='poi-icon' src={poiSvg} />
                                    <div className='h-title'>{hotspot.hotspot_name}</div>
                                </div>)
                        })
                    }
                </div>
            </div>

            {selectedHotspot && <div className={`popup-contaner ${closePop ? 'close-pop' : 'open-pop'}`}  >
                <div className='popup-background'>
                </div>
                <div className='popup'>
                    <div className='popup-header'>
                        <div className='popup-title'>
                            {selectedHotspot.hotspot_data.title}
                        </div>
                        <IoCloseSharp className="icon icon-header icon-close" onClick={onClosePopup} />
                    </div>
                    {selectedHotspot.hotspot_data.gallery.length !== 0 && <div className='popup-asset-gallery'>
                        {selectedHotspot.hotspot_data.gallery[0].mime_type === 'image/jpg' && <img src={selectedHotspot.hotspot_data.gallery[0].asset_url} />}
                        {selectedHotspot.hotspot_data.gallery[0].mime_type === 'video/mp4' &&
                            <video width="100%" controls autoPlay>
                                <source src={selectedHotspot.hotspot_data.gallery[0].asset_url} mime_type="video/mp4" />
                            </video>
                        }


                    </div>}
                    {selectedHotspot.hotspot_type === "artefact" && <div className='popup-object-container'>
                        <Artefact />
                    </div>}
                    {selectedHotspot.hotspot_data.description && <div className='popup-description'>
                        {selectedHotspot.hotspot_data.description}
                    </div>}
                </div>

            </div>}

            <video id="video" loop muted crossOrigin="anonymous" playsinline style={{ position: "absolute", top: "0px", left: "0px", height: "80dvh", display:"none" }} controls>

                <source src={`${window.location.origin}/assets/tour-videos/${space.space_video}`} />
            </video>

            {space.history.length !== 0 && <Box className="year-slider" sx={{ width: "40%" }}>
      <Slider
        aria-label="Custom marks"
        defaultValue={0}
        onChange={onYearMarkerChange}
        step={100/space.history.length}
        marks={yearMarks}
      />
    </Box>}

            <Footer className="footer-tour">
                <Link className="link" onClick={onToggleAutoRotate}>
                    {!auto && <BiMobileVibration className="icon icon-footer" />}
                    {auto && <TbDeviceMobileOff className="icon icon-footer" />}
                </Link>
                <Link className='link' onClick={onTogglePlayPauseVideo}>
                        { (videoState === 'pause' || videoState === 'stop') && <LuPlay className='icon icon-footer'/>}
                        { videoState === 'play' && <GrPause className='icon icon-footer'/>}
                </Link>
                {videoState !== 'stop' && <Link className='link' onClick={onStopVideo}>
                    { videoState !== 'stop' && <GrStop className='icon icon-footer'/>}
                </Link>}
            </Footer>
        </div>
    )
}

export default TourPage;


  
  
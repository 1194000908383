import { ActionTypes } from "../constants/actionTypes";

const initialState = {
    tours: {},
    selected_space: {}
}

export const toursReducer = (state = initialState, { type, payload}) => {
    switch(type){
        case ActionTypes.LOAD_TOURS:
            return { ...state, tours: payload};
        case ActionTypes.SELECTED_SPACE:
            return { ...state, selected_space: payload};
        default:
            return state;

    }
};


import { Link } from "react-router-dom";
import Header from "../components/Header";
import "./../styles/HomePage.scss";

import { FaRegMap } from "react-icons/fa";
import { MdOutlineFort } from "react-icons/md";
import { MdDirectionsWalk } from "react-icons/md";
import { BsQrCodeScan } from "react-icons/bs";
import { LuCamera } from "react-icons/lu";
import { MdOutlineDiamond } from "react-icons/md";
import { GiForkKnifeSpoon } from "react-icons/gi";
import { ImManWoman } from "react-icons/im";
import { MdOutlineMedicalServices } from "react-icons/md";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlineFeedback } from "react-icons/md";
import ScanPage from "./ScanPage";

const HomePage = () => {

    return (
        <>
            <div id="HomeContainer" className="app-container">
                <Header title="Home" />
                <div className="link-container">
                    <div className="links">

                        <Link className="link" to={'/map'}>
                            <FaRegMap className="icon icon-home" />
                            <span>MAP</span>
                        </Link>
                        <Link className="link" to={'/model'}>
                            <MdOutlineFort className="icon icon-home" />
                            MODEL
                        </Link>
                        <Link className="link" to={"./tour/1"}>
                            <MdDirectionsWalk className="icon icon-home" />
                            TOUR
                        </Link>
                        <Link className="link" to={"./scan"}>
                            <BsQrCodeScan className="icon icon-home" />
                            SCAN
                        </Link>
                        <Link className="link">
                            <LuCamera className="icon icon-home" />
                            SELFIE
                        </Link>
                        <Link className="link">
                            <MdOutlineDiamond className="icon icon-home" />
                            HUNT
                        </Link>
                        <Link className="link">
                            <GiForkKnifeSpoon className="icon icon-home" />
                            FOOD
                        </Link>
                        <Link className="link">
                            <ImManWoman className="icon icon-home" />
                            TOILETS
                        </Link>
                        <Link className="link">
                            <MdOutlineMedicalServices className="icon icon-home" />
                            MEDICAL
                        </Link>
                        <Link className="link">
                            <IoMdHelpCircleOutline className="icon icon-home" />
                            HOW TO
                        </Link>



                    </div>
                </div>
                <div className="footer">
                    <div className="col-left col">
                        <Link className="link">
                            <IoSettingsOutline className="icon icon-footer" />
                            <span>SETTINGS</span>
                        </Link>
                        <Link className="link" >
                            <IoIosInformationCircleOutline className="icon icon-footer" />
                            <span>INFO</span>
                        </Link>
                    </div>
                    <div className="col-right col">
                        <Link className="link">
                            <MdOutlineFeedback className="icon icon-footer" />
                            <span>FEEDBACK</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )

}

export default HomePage;